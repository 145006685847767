/**
 * @file Form送信
 */
'use strict';
import Common from '../util/_common';

/**
 * @classname FormDatePickerView
 * @classdesc DatePicker
 */
export default class FormDatePickerView {

	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormDatePickerView.constructor');
		this._$e = $e;
		const pikadayConf = {
			toString: function(date, format) { // 出力フォーマット整形 形式「20180101」
				const day = ('0' + date.getDate()).slice(-2);
				const month = ('0' + (date.getMonth() + 1)).slice(-2);
				const year = date.getFullYear();
				return `${year}/${month}/${day}`;
				
			},
			maxDate: function(){ // 表示期間リミット(Date オブジェクト)
				const newDate = new Date();
				const year = newDate.getFullYear() + 1; // 一年後
				const month = newDate.getMonth();
				const date = newDate.getDate();
				return new Date(year,month,date);
			},
			i18n: {
				previousMonth : '前月',
				nextMonth     : '次月',
				months        : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月',],
				weekdays      : ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
				weekdaysShort : ['日','月','火','水','木','金','土']
			}
		};
		let minDate = new Date();
		if($e.attr('data-min-date') === 'true'){
			let dt = new Date();
			dt.setDate(dt.getDate() + 7);
			minDate = dt;
		}
　      if($e.attr('data-spot-date') === 'true'){
			let dt = new Date();
			dt.setDate(dt.getDate() + 1);
			minDate = dt;
		}
		this._datepicker = new Pikaday({
			field: $('[data-datepicker=true]')[0],
			format: 'YYYY/MM/DD',
			toString: pikadayConf.toString,
			minDate: minDate,
			defaultDate: minDate,
			maxDate: pikadayConf.maxDate(),
			i18n: pikadayConf.i18n,
			yearSuffix: '年',
			showMonthAfterYear: true,
			onSelect: () => {
				$('.pickup-start-date').removeClass('is-invalid');
			}
		});

	};
}