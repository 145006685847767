/**
 * @file 見積再計算
 *
 */
'use strict';

/**
 * @classname EstimateCalculateView
 * @classdesc 見積再計算
 */
export default class EstimateCalculateView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('EstimateCalculateView.constructor');
		this._$table = $e.closest('.table');
		this._$quantity = $e.find('.quantity');
		this._$unitPrice = $e.find('.unit_price');
		this._$price = $e.find('.price');
		this._$quantity.on('blur', () => {this.calculateRow();});
		this._$quantity.on('click', () => {this.calculateRow();});
		this._$unitPrice.on('blur', () => {this.calculateRow();});
		this._$unitPrice.on('click', () => {this.calculateRow();});

	};

	/**
	 * 行計算
	 *
	 * @memberof EstimateCalculateView
	 * @return {undefined}
	 */
	calculateRow() {
		console.log('EstimateCalculateView.calculateRow');
		const quantity = this._$quantity.val();
		if(quantity && quantity !== ''){
			const unitPrice =this._$unitPrice.val();
			if(unitPrice && unitPrice !== '') {
				const price = Math.ceil(quantity * unitPrice);
				this._$price.val(price);
			}else{
				this._$price.val('');
			}
		}else{
			this._$price.val('');
		}
		this.calculateTotal();
	}

	/**
	 * 合計計算
	 *
	 * @memberof EstimateCalculateView
	 * @return {undefined}
	 */
	calculateTotal() {
		console.log('EstimateCalculateView.calculateTotal');
		let total = 0;
		this._$table.find('.price').each((idx, e) => {
			const $e = $(e);
			if($e.val() && $e.val() !== ''){
				total = total + parseInt($e.val());
			}
		});
		let taxTotal = Math.ceil(total * 1.1);
		$('.total-price').text(`${total.toLocaleString()}円`);
		$('.tax-total-price').text(`${taxTotal.toLocaleString()}円`);
	}
}
