/**
 * @file 排出変更
 */
'use strict';

import Common from '../util/_common';

/**
 * @classname FormExportChangeView
 * @classdesc 排出変更
 */
export default class FormExportChangeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormExportChangeView.constructor');
		this._$e = $e;
		this._$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormExportChangeView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormExportChangeView.onClick');
		const $target = $('.row--export-detail');
		if(this._$e.hasClass('export-detail')){
			$target.slideDown();
		}else{
			$target.slideUp();
		}
	};
}

