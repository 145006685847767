/**
 * @file Form送信
 */
'use strict';

import Common from '../util/_common';

/**
 * @classname FormSubmitView
 * @classdesc フォームサブミット
 */
export default class FormSubmitView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormSubmitView.constructor');
		this._controller = controller;
		this._$e = $e;
		this._form = $e.attr('data-form');
		this._$form = $(this._form);
		console.log(this._$form);
		this._process = $e.attr('data-process');
		this._timing = $e.attr('data-timing');
		this._submit = true;
		this._$e.on('click', (e) => {
			e.preventDefault();
			if(!$e.hasClass('clicked')){
				$e.addClass('clicked');
				this.onClick();
			}
		});
		this._controller.on('completePreProcessing', () => {this.validate();});
		this._controller.on('completeValidate', () => {this.completeValidate();});
		this._controller.on('completePostProcessing', () => {this.submit();});
		this._controller.on('preProcessingError', () => {this.onError();});
		this._controller.on('initialize', () => {this.initialize();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormSubmitView.onClick');
		if(this._process === 'true'){
			if(this._timing === 'pre' || this._timing === 'both') {
				console.log('前処理実施');
				this._processing.preProcessing(this._$form);
			}else{
				console.log('前処理SKIP');
				this.validate(this._$form);
			}
		}else{
			console.log('前処理SKIP');
			this.validate(this._$form);
		}
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	validate() {
		console.log('FormSubmitView.validate');
		console.log('バリデーションチェック');
		this._controller.validate(this._$form);
	};


	/**
	 * バリデーション完了
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	completeValidate() {
		console.log('FormSubmitView.completeValidate');
		console.log('バリデーション完了');
		console.log('エラーチェック');
		if(this._$form.find('.validate').hasClass('is-invalid')) this._submit = false;
		if(this._submit){
			this.postProcessing();
		}else{
			Common.showErrorModal('入力項目に誤りがあります');
			this.initialize();
		}
	};


	/**
	 * 後処理
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	postProcessing() {
		console.log('FormSubmitView.postProcessing');
		if(this._process === 'true'){
			if(this._timing === 'post' || this._timing === 'both') {
				console.log('後処理実施');
				this._processing.postProcessing(this._$form);
			}else{
				console.log('後処理SKIP');
				this.submit();
			}
		}else{
			console.log('後処理SKIP');
			this.submit();
		}
	};


	/**
	 * submit
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	submit() {
		console.log('FormSubmitView.submit');
		Common.submit(this._$form);
		this.initialize();
	};




	/**
	 * エラー
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	onError() {
		console.log('FormSubmitView.onError');
		Common.showErrorModal();
		this.initialize();
	};


	/**
	 * 初期化
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	initialize() {
		console.log('FormSubmitView.initialize');
		this._submit = true;
		this._controller._$form = null;
		this._$e.removeClass('clicked');
	};
}
