/**
 * @file Ajaxログイン
 */
'use strict';

import Common from '../util/_common';

/**
 * @classname FormAjaxLoginView
 * @classdesc Ajaxログイン
 */
export default class FormAjaxLoginView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('FormAjaxLoginView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$e.on('click', () => {this.onClick();});
		this._$form = $('#form-login');
		this._controller.on('completeValidateLogin', () => {this.onCompleteValidateLogin();});
		this._controller.on('ajaxLoginComplete', () => {this.onAjaxLoginComplete();});
		this._controller.on('error', () => {this.onError();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAjaxLoginView
	 * @return {undefined}
	 */
	onClick() {
		if(this._$e.hasClass('clicked')) return;
		console.log('FormAjaxLoginView.onClick');
		this._$e.addClass('clicked');
		this._controller.validateLogin($('#form-login'));
	};


	/**
	 * validate終了のコールバック
	 *
	 * @memberof FormAjaxLoginView
	 * @return {undefined}
	 */
	onCompleteValidateLogin() {
		console.log('FormAjaxLoginView.onCompleteValidateLogin');
		let error = false;
		this._$form.find('.validate').each((idx, e) => {
			if($(e).hasClass('is-invalid')) error = true;
		});
		if(error) {
			Common.showErrorModal();
			this._$e.removeClass('clicked');
			return;
		}else{
			const email = this._$form.find('.email').val();
			const password = this._$form.find('.password').val();
			this._controller.ajaxLogin(email, password);
		}
	};


	/**
	 * Ajax通信完了
	 *
	 * @memberof FormAjaxLoginView
	 * @return {undefined}
	 */
	onAjaxLoginComplete() {
		console.log('FormAjaxLoginView.onAjaxLoginComplete');
		const loginData = this._controller._loginData;
		console.log(loginData);
		$('#form-order .client_name').val(loginData.client_name);
		$('#form-order .rep').val(loginData.rep);
		$('#form-order .zip_code').val(loginData.zip_code);
		$('#form-order .address').val(loginData.address);
		$('#form-order .tel').val(loginData.tel);
		$('#form-order .industries_id').val(loginData.industries_id);
		$('#form-order .email').val(loginData.email);
		const $modal = $('.modal--login');
		const $background = $('.modal-background');
		$modal.fadeOut(500);
		$background.fadeOut(500);
		this._$e.removeClass('clicked');
		setTimeout(() => {$background.remove();},500);
		setTimeout(() => {$modal.removeClass('show');},500);
		this.initialize();
	};


	/**
	 * 初期化
	 *
	 * @memberof FormAjaxLoginView
	 * @return {undefined}
	 */
	initialize() {
		console.log('FormAjaxLoginView.onError');
		this._$e.removeClass('clicked');
		Common.hideLoading();
	};
}
