/**
 * @file モーダル
 *
 */
'use strict';

/**
 * @classname ModalView
 * @classdesc モーダル
 */
export default class ModalView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ModalView.constructor');
		this._$e = $e;
		this._$target = $($e.attr('data-target'));
		this._$close = this._$target.find('.anchor--modal-close');
		this._$closeBtn = this._$target.find('.btn--modal-close');
		this._$background = $('.modal-background');
		this._$e.on('click', () => {this.onClick();});
		this._$close.on('click', () => {this.closeModal();});
		this._$closeBtn.on('click', () => {this.closeModal();});
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof ModalView
	 * @return {undefined}
	 */
	onClick() {
		console.log('ModalView.onClick');
		const $e = this._$e;
		if($e.hasClass('btn--transaction-message')){
			// メッセージ
			const message = $e.attr('data-message');
			$('.list__item--link-message .btn').attr('href', message);
			// 案件詳細
			const detail = $e.attr('data-detail');
			$('.list__item--link-detail .btn').attr('href', detail);
			// 見積比較
			if($e.attr('data-estimate') && $e.attr('data-estimate') !== ''){
				const estimate = $e.attr('data-estimate');
				console.log(`見積比較あり: ${estimate}`);
				$('.list__item--link-estimate').show().find('.btn').attr('href', estimate);
			}else{
				$('.list__item--link-estimate').hide().find('.btn').attr('href', '');
			}
			// 発注内容
			if($e.attr('data-order') && $e.attr('data-order') !== ''){
				const order = $e.attr('data-order');
				console.log(`発注内容あり: ${order}`);
				$('.list__item--link-order').show().find('.btn').attr('href', order);
			}else{
				$('.list__item--link-order').hide().find('.btn').attr('href', '');
			}
			// 確定見積
			if($e.attr('data-fix-estimate') && $e.attr('data-fix-estimate') !== ''){
				const fixEstimate = $e.attr('data-fix-estimate');
				console.log(`確定見積あり: ${fixEstimate}`);
				$('.list__item--link-fix-estimate').show().find('.btn').attr('href', fixEstimate);
			}else{
				$('.list__item--link-fix-estimate').hide().find('.btn').attr('href', '');
			}
			// 契約内容
			if($e.attr('data-contract') && $e.attr('data-contract') !== ''){
				const contract = $e.attr('data-contract');
				console.log(`契約内容あり: ${contract}`);
				$('.list__item--link-contract').show().find('.btn').attr('href', contract);
			}else{
				$('.list__item--link-contract').hide().find('.btn').attr('href', '');
			}
			// 実績情報
			if($e.attr('data-result') && $e.attr('data-result') !== ''){
				const result = $e.attr('data-result');
				console.log(`実績情報あり: ${result}`);
				$('.list__item--link-result').show().find('.btn').attr('href', result);
			}else{
				$('.list__item--link-result').hide().find('.btn').attr('href', '');
			}
			// 口コミ評価
			if($e.attr('data-review') && $e.attr('data-review') !== ''){
				const review = $e.attr('data-review');
				console.log(`口コミ評価あり: ${review}`);
				$('.list__item--link-review').show().find('.btn').attr('href', review);
				$('.text--review-info').show();
			}else{
				$('.list__item--link-review').hide().find('.btn').attr('href', '');
				$('.text--review-info').hide();
			}
			// 辞退理由
			if($e.attr('data-decline-reason') && $e.attr('data-decline-reason') !== ''){
				const decline = $e.attr('data-decline-reason');
				console.log(`辞退理由: ${decline}`);
				$('.declinereason').html(decline);
			}
			// キャンセル
			if($e.attr('data-cancel-reason') && $e.attr('data-cancel-reason') !== ''){
				const cancel = $e.attr('data-cancel-reason');
				console.log(`キャンセル理由: ${cancel}`);
				$('.cancelreason').html(cancel);
			}
		}
		this._$target.find('h3 > strong').html($e.attr('data-project-name'));
		this._$target.fadeIn(500).addClass('show');
		$('body').append('<div class="modal-background"></div>');
		this._$background = $('.modal-background');
		this._$background.fadeIn(500);
		this._$target.on('click', () => {this.closeModal();});
		this._$target.find('.anchor--modal-close').on('click', () => {this.closeModal();});
		this._$target.find('.modal__content').on('click', (e) => {
			e.stopPropagation();
		});
	}
	/**
	 * メニューを閉じる
	 *
	 * @memberof ModalView
	 * @return {undefined}
	 */
	closeModal(){
		console.log('ModalView.closeModal');
		this._$target.fadeOut(500);
		this._$background.fadeOut(500);
		setTimeout(() => {this._$background.remove();},500);
		setTimeout(() => {this._$target.removeClass('show');},500);
	}

}
