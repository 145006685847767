/**
 * @file カート商品削除
 *
 */
'use strict';
import Common from '../util/_common';

/**
 * @classname AnchorPopoverView
 * @classdesc カート商品削除
 */
export default class AnchorPopoverView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('AnchorPopoverView.constructor');
		this._$e = $e;
		this._text = $e.attr('data-text');
		this._width = parseInt($e.attr('data-width'));
		if($e.hasClass('anchor--sp')){
			$e.on('click', () => {this.onClick();});
		}else{
			$e.on('mouseenter', () => {this.showText();});
			$e.on('mouseleave', () => {this.hideText();});
		}
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof AnchorPopoverView
	 * @return {undefined}
	 */
	onClick() {
		console.log('AnchorPopoverView.onClick');
		if(this._$e.hasClass('open')){
			this.hideText();
		}else{
			this.showText();
		}
	};


	/**
	 * MouseEnterのコールバック
	 *
	 * @memberof AnchorPopoverView
	 * @return {undefined}
	 */
	showText() {
		console.log('AnchorPopoverView.showText');
		const left = - (this._width) / 2 + 5;
		this._$e.addClass('open');
		this._$e.append(`<span class="popover" style="width:${this._width}px;left:${left}px;display:none;">${this._text}</span>`);
		this._$e.find('span').fadeIn(500);
	};


	/**
	 * MouseLeaveのコールバック
	 *
	 * @memberof AnchorPopoverView
	 * @return {undefined}
	 */
	hideText() {
		console.log('AnchorPopoverView.hideText');
		this._$e.removeClass('open');
		const tooltip = this._$e.find('span');
		tooltip.fadeOut(500);
		setTimeout(() => {tooltip.remove();}, 500);
	};


}
