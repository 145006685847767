/**
 * @file ハイフン削除
 *
 */
'use strict';

/**
 * @classname FormRemoveHyphenView
 * @classdesc ハイフン削除
 */
export default class FormRemoveHyphenView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormRemoveHyphenView.constructor');
		this._$e = $e;
		this._$e.on('keyup', () => {this.onKeyup();});
	};

	/**
	 * keyupのコールバック
	 *
	 * @memberof FormRemoveHyphenView
	 * @return {undefined}
	 */
	onKeyup() {
		console.log('FormRemoveHyphenView.onKeyup');
		let str = this._$e.val();
		str = str.replace(/-/g, "");
		str = str.replace(/ー/g, "");
		this._$e.val(str);
	}
}
