/**
 * @file 行削除
 */
'use strict';

/**
 * @classname BtnDeleteRow
 * @classdesc 行削除
 */
export default class BtnDeleteRow {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnDeleteRow.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$table = $e.closest('.table');
		$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnDeleteRow
	 * @param {object} e - event
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnDeleteRow.deleteRow');
		this._$e.closest('.table__tr').remove();
		let total = 0;
		this._$table.find('.price').each((idx, e) => {
			const $e = $(e);
			if($e.val() && $e.val() !== ''){
				total = total + parseInt($e.val());
			}
		});
		let taxTotal = Math.ceil(total * 1.1);
		$('.total-price').text(`${total.toLocaleString()}円`);
		$('.tax-total-price').text(`${taxTotal.toLocaleString()}円`);

	};
}
