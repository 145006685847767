/**
 * @file Form Controller
 */

'use strict';
import Listener from '../util/_listener';
import FormModel from '../model/_form-model';

let instance = null;

/**
 * @classname Form
 * @classdesc Form Controller
 */
export default class Form {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Form.constructor');
		this._listener = new Listener();
		this._$form = null;
		this._submit = true;

		this._regularType = [];
		this._imageId = null;
		this._type = null;

		this._model = new FormModel();

		this._model.on('ajaxLoginComplete', () => {
			this._loginData = this._model._data;
			this._listener.trigger('ajaxLoginComplete');
		});
		this._model.on('imageUploadComplete', () => {
			this._imageData = this._model._data;
			this._listener.trigger('imageUploadComplete');
		});
		this._model.on('imageDestroyComplete', () => {
			this._imageData = this._model._data;
			this._listener.trigger('imageDestroyComplete');
		});
		this._model.on('error', () => {this._listener.trigger('error');});

		instance = this;
		return instance;
	};

	/**
	 * 前処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePreProcessing($form) {
		console.log('Form.completePreProcessing');
		this._$form = $form;
		this._listener.trigger('completePreProcessing');
	};


	/**
	 * 前処理でのエラー
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	preProcessingError() {
		console.log('Form.preProcessingError');
		this._listener.trigger('preProcessingError');
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validate($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validate');
		setTimeout(() => {
			this._listener.trigger('completeValidate');
		}, 500);
	};


	/**
	 * ログインバリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validateLogin($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validateLogin');
		setTimeout(() => {
			this._listener.trigger('completeValidateLogin');
		}, 500);
	};


	/**
	 * 後処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePostProcessing($form) {
		console.log('Form.completePostProcessing');
		this._$form = $form;
		this._listener.trigger('completePostProcessing');
	};


	/**
	 * サブミットボタン初期化
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	initialize() {
		console.log('Form.completePostProcessing');
		this._listener.trigger('initialize');
	};


	/**
	 * ログイン
	 *
	 * @memberof Form
	 * @param {string} email - メールアドレス
	 * @param {string} password - パスワード
	 * @return {undefined}
	 */
	ajaxLogin(email, password) {
		console.log('Form.ajaxLogin');
		this._model.ajaxLogin(email, password);
	};


	/**
	 * 画像アップロード
	 *
	 * @memberof Form
	 * @param {blob} file - ファイル
	 * @param {string} type - タイプ 1: 定期 2:スポット（外観） 3:スポット（引き渡し場所）
	 * @param {string} projectId - プロジェクトID
	 * @return {undefined}
	 */
	uploadImage(file, type, projectId) {
		console.log('Form.uploadImage');
		this._type = type;
		this._model.uploadImage(file, type, projectId);
	};


	/**
	 * 画像削除
	 *
	 * @memberof Form
	 * @param {string} id - イメージID
	 * @param {string} projectId - プロジェクトID
	 * @return {undefined}
	 */
	destroyImage(id, projectId) {
		console.log(`Form.destroyImage id:${id}/projectId:${projectId}`);
		this._imageId = id;
		this._model.destroyImage(id, projectId);
	};


	/**
	 * listener on
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
