/**
 * @file 数量四捨五入
 *
 */
'use strict';

/**
 * @classname QuantityRoundView
 * @classdesc 数量四捨五入
 */
export default class QuantityRoundView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('QuantityRoundView.constructor');
		let quantity = $e.attr('data-quantity');
		const unit = $e.attr('data-unit');
		console.log(`${quantity}/${unit}`);
		if(quantity.match(/.00$/)) {
			quantity = quantity.substr(0, quantity.length - 3);
			console.log(quantity)
		}else{
			quantity = Math.round(quantity * 10) / 10
		}
		if($e.prop('tagName').toLowerCase() === 'input'){
			$e.val(quantity);
		}else{
			if(unit){
				$e.text(`${quantity}${unit}`);
			}else{
				$e.text(quantity);
			}
		}
	};
}
