/**
 * @file 行追加
 */
'use strict';

import Common from '../util/_common';
import ApplyClass from '../util/_apply-class';
import FormValidateView from './_form-validate-view';
import BtnDeleteRowView from './_btn-delete-row-view';
import EstimateCalculateView from './_estimate-calculate-view';

/**
 * @classname BtnAddRowView
 * @classdesc 行追加
 */
export default class BtnAddRowView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnAddRowView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$tbody = $e.closest('.table__tbody');
		$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnAddRowView
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnAddRowView.onClick');
		const additionalRow = `
<tr class="table__tr additional-row">
	<td class="table__td"><input class="input-text" name="other_title[]" value="" type="text"><input name="other_waste_code[]" value="" type="hidden"></td>
	<td class="table__td"><input class="input-number quantity" name="other_quantity[]" value="1" type="number"></td>
	<td class="table__td tac vam">
		<div class="input-select">
			<select class="input-select__select other_waste_unit_id" name="other_waste_unit_id[]">
				<option value="5">個</option>
				<option value="3">kg</option>
				<option value="2">m3</option>
				<option value="4">L</option>
				<option value="1">t</option>
				<option value="7">式</option>
				<option value="6">月</option>
				<option value="8">台</option>
				<option value="9">回</option>
			</select>
		</div>
	</td>
	<td class="table__td"><input class="input-number unit_price validate" name="other_unit_price[]" value="" type="number" data-validate="empty"></td>
	<td class="table__td"><input class="input-number price" name="other_price[]" value="" type="number" readonly></td>
	<td class="table__td"><input class="input-text" name="other_remarks[]" value="" type="text"></td>
	<td class="table__td">
		<a class="btn btn--add-row btn--sm" href="javascript:void(0);"><i class="fas fa-plus"></i></a>
		<a class="btn btn--delete-row btn--sm" href="javascript:void(0);"><i class="fas fa-minus"></i></a>
	</td>
</tr>`;
		this._$tbody.append(additionalRow);
		const $lastAdditionalRow = $('.additional-row:last');
		const $addRow = $lastAdditionalRow.find('.btn--add-row');
		const $deleteRow = $lastAdditionalRow.find('.btn--delete-row');
		const controller = this._controller;
		$lastAdditionalRow.find('.validate').each(function(idx, e){
			const $e = $(e);
			ApplyClass.apply(FormValidateView, $e, [$e, controller]);
		});
		ApplyClass.apply(BtnAddRowView, $addRow, [$addRow, controller]);
		ApplyClass.apply(BtnDeleteRowView, $deleteRow, [$deleteRow, controller]);
		ApplyClass.apply(EstimateCalculateView, $lastAdditionalRow, [$lastAdditionalRow, controller]);
	};


	/**
	 * エラーモーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	showErrorModal() {
		console.log('BtnAddRowView.showErrorModal');
		const modal =
`<div class="fade modal modal--error" role="dialog" tabindex="-1" aria-hidden="true">
	<div class="modal__content">
		<div class="modal__content__body">
			<div class="image-box">
				<i class="fas fa-exclamation-triangle"></i>
			</div>
			<p class="text">種類と数量を入力してから追加してください。</p>
			<div class="block block--btn-box">
				<button class="btn btn--default btn--error-close" type="button">閉じる
					<i class="btn__caret" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</div>
</div>`;
		$('body').append(modal);
		$('.btn--error-close').click(() => {
			Common.hideErrorModal();
		});
	}
}
