/**
 * @file 画像削除
 */
'use strict';

import ApplyClass from '../util/_apply-class';
import FormValidateView from './_form-validate-view';
import Common from '../util/_common';


/**
 * @classname FormImageDeleteView
 * @classdesc 画像削除
 */
export default class FormImageDeleteView {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormImageDeleteView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._id = this._$e.attr('data-id');
		this._projectId = this._$e.attr('data-project-id');
		$e.on('click', () => {this.onClick();});
		this._controller.on('imageDestroyComplete', () => {
			if(this._id === this._controller._imageId){
				this.onImageDestroyComplete();
			}
		});
	};


	/**
	 * 画像削除ボタンクリック
	 *
	 * @memberof FormImageDeleteView
	 * @param {event) e - イベント
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormImageDeleteView.onClick');
		this._controller.destroyImage(this._id, this._projectId);
	};


	/**
	 * 画像の削除
	 *
	 * @memberof FormImageDeleteView
	 * @return {undefined}
	 */
	onImageDestroyComplete() {
		console.log('FormImageDeleteView.onImageDestroyComplete');
		this._controller._imageId = null;
		this._$e.closest('.upload-image-wrap').remove();
	};
}
