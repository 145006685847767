/**
 * @file タブ
 */
'use strict';


/**
 * @classname TabNavView
 * @classdesc スライダー
 */
export default class TabNavView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('TabNavView.constructor');
		this._$e = $e;
		this._controller = controller;
		const target = $e.attr('data-target');
		this._$target = $(target);
		this._$tabNavs = $e.closest('.tab').find('.tab__navs .tab__navs__item__link');
		this._$tabPane = $e.closest('.tab').find('.tab__contents .tab__contents__pane');
		this._tabNo = $e.attr('data-tab-no');
		$e.on('click', () => {this.onClick();});
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TabNavView
	 * @params {int} no - オプションNo
	 * @return {array} options - オプション
	 */
	onClick() {
		console.log('TabNavView.onClick');
		this._$tabNavs.removeClass('active');
		this._$tabPane.removeClass('active');
		this._$e.addClass('active');
		this._$target.addClass('active');
	}
}
