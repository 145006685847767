/**
 * @file 例文設定
*/
'use strict';

import Common from '../util/_common';

/**
 * @classname BtnSetTemplateView
 * @classdesc 行追加
 */
export default class BtnSetTemplateView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e) {
		console.log('BtnSetTemplateView.constructor');
		this._$e = $e;
		this._textarea = $('.input-textarea.schedule');
		$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnSetTemplateView
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnSetTemplateView.onClick');
		this._sampleText =
`【可燃ごみ回収スケジュール】
　水曜　10:00～15:00
　金曜　10:00～15:00

【不燃ごみ回収スケジュール】
　火曜　10:00～15:00

【ビン缶ペット回収スケジュール】
　火曜　10:00～15:00

【発泡スチロール回収スケジュール】
　月曜　10:00～15:00
`;

		if(this._textarea.val() && this._textarea.val() !== ''){
			this.showErrorModal();
		
		}else{
			this._textarea.val(this._sampleText);
			
		}
		
	};


	/**
	 * エラーモーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	showErrorModal() {
		console.log('BtnSetTemplateView.showErrorModal');
		const modal =
`<div class="fade modal modal--error" role="dialog" tabindex="-1" aria-hidden="true">
	<div class="modal__content">
		<div class="modal__content__body">
			<div class="image-box">
				<i class="fas fa-exclamation-triangle"></i>
			</div>
			<p class="text">希望スケジュールが入力されています。</p>
			<div class="block block--btn-box">
				<button class="btn btn--default btn--error-close" type="button">閉じる
					<i class="btn__caret" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</div>
</div>`;
		$('body').append(modal);
		$('.btn--error-close').click(() => {
			Common.hideErrorModal();
		});
		$('.btn--set-text').click(() => {
			this._textarea.val(this._sampleText);
			Common.hideErrorModal();
		});
	}
}
