/**
 * @file 開始タイミング変更
 */
'use strict';

import Common from '../util/_common';

/**
 * @classname FormPickupChangeView
 * @classdesc 開始タイミング変更
 */
export default class FormPickupChangeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormPickupChangeView.constructor');
		this._$e = $e;
		this._$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormPickupChangeView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormPickupChangeView.onClick');
		const $target = $('.row--pickup');
		const pickupStartDate = $('.pickup-start-date');
		if(this._$e.hasClass('decide-date')){
			$target.slideDown();
			pickupStartDate.removeClass('ignore-validate');
		}else{
			$target.slideUp();
			pickupStartDate.addClass('ignore-validate');
		}
	};
}
