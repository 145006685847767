/**
 * @file ファイルドロップ
 */
'use strict';

import ApplyClass from '../util/_apply-class';
import Common from '../util/_common';
import FormImageDeleteView from './_form-image-delete-view';

/**
 * @classname FormFileDropView
 * @classdesc ファイルドロップ
 */
export default class FormFileDropView {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormFileDropView.constructor');
		this._controller = controller;
		this._$e = $e;
		const $blockFileUpload = $e.closest('.block--file-upload');
		this._$inputFile = $blockFileUpload.find('input');
		this._$inputFileWrap = $blockFileUpload.find('.block--file-input');
		this._type = $e.attr('data-type');
		this._num = $e.attr('data-num');
		this._no = parseInt($e.attr('data-no'));
		this._projectId = $e.attr('data-project-id');
		this._maxMB = 10;
		this._maxSize = 1024 * 1024 * this._maxMB;
		this._orientation = 1;
		this._$photoArea = $e.closest('.form__item__content__inner').find('.block--photo-area');
		this._$e.on('dragover', (e) => {this.onDragover(e);});
		this._$e.on('dragleave', () => {this.onDragleave();});
		this._$e.on('drop', (e) => {this.onDrop(e);});
		this._$e.on('click', () => {this.onClick();});
		this._controller.on('imageUploadComplete', () => {
			console.log(`${this._type}/${this._controller._type}`);
			if(this._type === this._controller._type) this.onImageUploadComplete();
		});
		this._$inputFile.on('change', (e) => {this.onChange(e);});
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onClick(){
		console.log('FormFileDropView.onClick');
		this._$inputFile.click();
	}
	/**
	 * ドラッグオーバーのコールバック
	 *
	 * @memberof FormFileDropView
	 * @param {object} e - イベント
	 * @return {undefined}
	 */
	onDragover(e) {
		console.log('FormFileDropView.onDragover');
		e = e.originalEvent;
		e.dataTransfer.dropEffect = 'copy';
		e.preventDefault();
		this._$e.addClass('dragover');
	};
	/**
	 * ドラッグリーブのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onDragleave() {
		console.log('FormFileDropView.onDragleave');
		this._$e.removeClass('dragover');
	};
	/**
	 * ドロップのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onDrop(e) {
		console.log('FormFileDropView.onDrag');
		e = e.originalEvent;
		e.preventDefault();
		this._$e.removeClass('dragover');
		this._files = e.dataTransfer.files;
		if(this._no === 3){
			Common.showErrorModal('画像は3枚までです。');
			return;
		}
		if(this._files.length > 1) {
			Common.showErrorModal('画像は1枚ずつあげてください。');
			return;
		}
		const file = this._files[0];
		if (file.size > this._maxSize) {
			Common.showErrorModal('画像サイズは' + this._maxMB + 'MB以下の必要があります。');
			return;
		}
		this._controller.uploadImage(file, this._type, this._projectId);
	};
	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onChange(e) {
		console.log('FormFileDropView.onChange');
		if(this._no === 3) {
			Common.showErrorModal('画像は3枚までです。');
			const inputFile = '<input class="input-file" type="file" style="display:none;">';
			this._$inputFileWrap.html(inputFile);
		}else{
			const files = e.target.files;
			const reader = new FileReader();
			reader.onload = this._controller.uploadImage(files[0], this._type, this._projectId);
		}
	};

	/**
	 * 画像の表示
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onImageUploadComplete() {
		console.log('FormFileDropView.onImageUploadComplete');
		this._no ++;
		this._$e.attr('data-no', this._no);
		const imageData = this._controller._imageData;
		const img = `<div class="upload-image-wrap"><div class="upload-image" style="background: #f2f2f2 url(${imageData.url}) no-repeat 50% 50%/contain;"></div><a href="javascript:void(0);" class="anchor--delete-image" data-id="${imageData.id}" data-project-id="${this._projectId}">&times;</a><input type="hidden" name="${window.const.FILE_UPLOAD_NAME}" value="${imageData.url}"></div>`;
		this._$photoArea.append(img);
		const inputFile = '<input class="input-file" type="file" style="display:none;">';
		this._$inputFileWrap.html(inputFile);
		const $deleteImage = $('.anchor--delete-image');
		$deleteImage.off();
		$deleteImage.each((idx, e) => {
			const $e = $(e);
			ApplyClass.apply(FormImageDeleteView, $e, [$e, this._controller])
		});
	};
}
