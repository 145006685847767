/**
 * @file ヘッダーメニューボタン
 *
 */
'use strict';

/**
 * @classname HeaderMenuView
 * @classdesc ヘッダーメニューボタン
 */
export default class HeaderMenuView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('HeaderMenuView.constructor');
		this._$e = $e;
		this._$background = $('.drawer__background');
		this._$menu = $('.drawer__menu');
		this._$close = $('.anchor--drawer-close');
		this._$e.on('click', () => {this.onClick();});
		this._$background.on('click', () => {this.closeMenu();});
		this._$close.on('click', () => {this.closeMenu();});
		this._offset = 0;
		this._$background.on('touchstart', (e) => {this.onTouchStart(e);});
		this._$background.on('touchmove', (e) => {this.onTouchMove(e);});
		this._$background.on('touchend', () => {this.onTouchEnd();});
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof HeaderMenuView
	 * @return {undefined}
	 */
	onClick() {
		console.log('HeaderMenuView.onClick');
		this._$menu.animate({'right': '-100vw'}, 300);
		this._$background.fadeIn(300);
	}
	/**
	 * メニューを閉じる
	 *
	 * @memberof HeaderMenuView
	 * @return {undefined}
	 */
	closeMenu(){
		console.log('HeaderMenuView.closeMenu');
		this._$background.fadeOut(300);
		this._$menu.animate({'right': '-200vw'}, 300);
		setTimeout(() => {
			this._$menu.find('.drawer__menu__item').scrollTop(0);
		},500);
	}
	/**
	 * タッチスタートのコールバック
	 *
	 * @memberof HeaderMenuView
	 * @return {undefined}
	 */
	onTouchStart(e) {
		//console.log('HeaderMenuView.onTouchStart');
		this._startPageX = e.targetTouches[0].pageX;
		//console.log(`touchstart: ${this._startPageX}`);
		this._touched = true;
	}
	/**
	 * タッチムーブのコールバック
	 *
	 * @memberof HeaderMenuView
	 * @return {undefined}
	 */
	onTouchMove(e) {
		//console.log('HeaderMenuView.onTouchMove');
		if(!this._touched) {
			return;
		}
		this._endPageX = e.targetTouches[0].pageX;
		//console.log(`touchmove: ${this._endPageX}`);
	}
	/**
	 * タッチエンドのコールバック
	 *
	 * @memberof HeaderMenuView
	 * @return {undefined}
	 */
	onTouchEnd() {
		//console.log('HeaderMenuView.onTouchEnd');
		if(!this._touched) {
			return;
		}
		this._touched = false;
		if(this._startPageX - this._endPageX < 50) {
			this.closeMenu();
		}
	}
}
