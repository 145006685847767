/**
 * @file スムーススクロール
 *
 */
'use strict';

/**
 * @classname SmoothScrollView
 * @classdesc TOPへ戻る
 */
export default class SmoothScrollView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('SmoothScrollView.constructor');
		this._$e = $e;
		$e.on('click', (e) => {this.onClick(e);});
	};

	/**
	 * @memberof SmoothScroll
	 */
	onClick(e){
		console.log('SmoothScrollView.onClick');
		e.preventDefault();
		const href = this._$e.attr('href');
		const target = $(href === '#' || href === '' ? 'html' : href);
		const position = target.offset().top - 63;
		$('html, body').animate({scrollTop: position}, 500, 'swing');
	}

}
