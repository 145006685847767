/**
 * @file チェックボックス
 */
'use strict';

import Common from '../util/_common';

/**
 * @classname FormInputRadioView
 * @classdesc チェックボックス
 */
export default class FormInputRadioView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormInputRadioView.constructor');
		this._$e = $e;
		this._name = $e.find('.input-radio__input').attr('name');
		this._$radio = $(`.input-radio__input[name=${this._name}]`).parent('.input-radio');
		this._$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormInputRadioView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormInputRadioView.onClick');
		this._$radio.removeClass('checked');
		this._$e.addClass('checked');
	};
}
