'use strict';

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
		value: function(searchElement, fromIndex) {

			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			// 1. Let O be ? ToObject(this value).
			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If len is 0, return false.
			if (len === 0) {
				return false;
			}

			// 4. Let n be ? ToInteger(fromIndex).
			//    (If fromIndex is undefined, this step produces the value 0.)
			var n = fromIndex | 0;

			// 5. If n ≥ 0, then
			//  a. Let k be n.
			// 6. Else n < 0,
			//  a. Let k be len + n.
			//  b. If k < 0, let k be 0.
			var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

			function sameValueZero(x, y) {
				return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
			}

			// 7. Repeat, while k < len
			while (k < len) {
				// a. Let elementK be the result of ? Get(O, ! ToString(k)).
				// b. If SameValueZero(searchElement, elementK) is true, return true.
				if (sameValueZero(o[k], searchElement)) {
					return true;
				}
				// c. Increase k by 1.
				k++;
			}

			// 8. Return false
			return false;
		}
	});
}

import Common from './util/_common';
import ApplyClass from './util/_apply-class';
import SmoothScrollView from './view/_smooth-scroll-view';

// basic
import HeaderMenuView from './view/_header-menu-view';
import AnchorPopoverView from './view/_anchor-popover-view';
import VolumeControlView from './view/_volume-control-view';
import BtnAddRowView from './view/_btn-add-row-view';
import BtnDeleteRowView from './view/_btn-delete-row-view';
import ModalView from './view/_modal-view';
import CheckSelectableView from './view/_check-selectable-view';
import TabNavView from './view/_tab-nav-view';
import EstimateCalculateView from './view/_estimate-calculate-view';
import BtnSetTemplateView from './view/_btn-set-template-view';
import QuantityRoundView from './view/_quantity-round-view';

// form関連
import Form from './controller/_form';
import FormSubmitView from './view/_form-submit-view';
import FormDatePickerView from './view/_form-datepicker-view';
import FormValidateView from './view/_form-validate-view';
import FormInputRadioView from './view/_form-input-radio-view';
import FormExportChangeView from './view/_form-export-change-view';
import FormPickupChangeView from './view/_form-pickup-change-view';
import FormAgreeView from './view/_form-agree-view';
import FormRemoveHyphenView from './view/_form-remove-hyphen-view';
import FormFileDropView from './view/_form-file-drop-view';
import FormAjaxLoginView from './view/_form-ajax-login-view';
import FormImageDeleteView from './view/_form-image-delete-view';

$(() => {
	Common.addHtmlClass();
	const FormController = new Form();
	$('.anchor--menu').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(HeaderMenuView, $e, [$e]);
	});
	$('.btn--add-row').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(BtnAddRowView, $e, [$e, FormController]);
	});
	$('.btn--delete-row').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(BtnDeleteRowView, $e, [$e]);
	});
	$('.table--fix-estimate .table__tbody .table__tr').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(EstimateCalculateView, $e, [$e]);
	});
	$('.btn--set-template').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(BtnSetTemplateView, $e, [$e]);
	});


	$('.anchor--popover').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(AnchorPopoverView, $e, [$e]);
	});
	$('.volume-control').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(VolumeControlView, $e, [$e]);
	});
	$('[data-modal=true]').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(ModalView, $e, [$e]);
	});
	$('.selectable').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(CheckSelectableView, $e, [$e]);
	});
	$('.tab__navs__item__link').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(TabNavView, $e, [$e]);
	});

	$('button[type=submit][data-form]').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormSubmitView, $e, [$e, FormController]);
	});
	$('button[type=button][data-form]').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormSubmitView, $e, [$e, FormController]);
	});
	$('[data-datepicker=true]').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormDatePickerView, $e, [$e, FormController]);
	});
	$('.validate').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormValidateView, $e, [$e, FormController]);
	});
	$('.remove-hyphen').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormRemoveHyphenView, $e, [$e, FormController]);
	});
	$('.input-radio').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormInputRadioView, $e, [$e]);
	});
	$('.input-radio.export').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormExportChangeView, $e, [$e]);
	});
	$('.input-radio.pickup-timing').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormPickupChangeView, $e, [$e]);
	});
	$('.agree .input-checkbox__input').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormAgreeView, $e, [$e]);
	});
	$('.file-upload').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormFileDropView, $e, [$e, FormController]);
	});
	$('.btn--ajax-login').each(function (idx, e) {
		const $e = $(e);
		ApplyClass.apply(FormAjaxLoginView, $e, [$e, FormController]);
	});
	$('.anchor--delete-image').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(FormImageDeleteView, $e, [$e, FormController]);
	});
	$('[data-quantity]').each(function(idx, e){
		const $e = $(e);
		ApplyClass.apply(QuantityRoundView, $e, [$e]);
	});
});
