/**
 * @file 容量変更
 */
'use strict';

/**
 * @classname CheckSelectableView
 * @classdesc 容量変更
 */
export default class CheckSelectableView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CheckSelectableView.constructor');
		this._$e = $e;
		this._$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof CheckSelectableView
	 * @return {undefined}
	 */
	onClick() {
		console.log('CheckSelectableView.onClick');
		if(this._$e.hasClass('checked')){
			this._$e.removeClass('checked');
		}else{
			this._$e.addClass('checked');
		}
	};
}
