/**
 * @file 容量変更
 */
'use strict';

/**
 * @classname VolumeControlView
 * @classdesc 容量変更
 */
export default class VolumeControlView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('VolumeControlView.constructor');
		this._$btnMinus = $e.find('.volume-minus');
		this._$btnPlus = $e.find('.volume-plus');
		this._$input = $e.find('input');
		this._$btnMinus.on('click', () => {this.onClickMinus();});
		this._$btnPlus.on('click', () => {this.onClickPlus();});
	};


	/**
	 * マイナスボタンクリックのコールバック
	 *
	 * @memberof VolumeControlView
	 * @return {undefined}
	 */
	onClickMinus() {
		console.log('VolumeControlView.onClickMinus');
		let value = this._$input.val();
		if(value === '0') return;
		value = (value * 10 - 1) / 10;
		this._$input.val(value);
	};


	/**
	 * プラスボタンクリックのコールバック
	 *
	 * @memberof VolumeControlView
	 * @return {undefined}
	 */
	onClickPlus() {
		console.log('VolumeControlView.onClickPlus');
		let value = this._$input.val();
		value = (value * 10 + 1) / 10;
		this._$input.val(value);
	};
}
