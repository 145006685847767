/**
 * @file 規約同意
 */
'use strict';

/**
 * @classname FormAgreeView
 * @classdesc 規約同意
 */
export default class FormAgreeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormAgreeView.constructor');
		this._$e = $e;
		this._$e.on('click', () => {this.onClick();});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAgreeView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormAgreeView.onClick');
		const $btn = $('.btn--conversion.btn--xl');
		if(this._$e.hasClass('checked')){
			this._$e.removeClass('checked');
			$btn.addClass('disabled');
		}else{
			this._$e.addClass('checked');
			$btn.removeClass('disabled');
		}
	};
}
